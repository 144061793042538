<template>
  <vue-final-modal
    v-model="modalsSync[ModalName.WALLET_TELEGRAM_APP]"
    class="wallet-telegram-app-model"
    :clickToClose="false"
    :focus-trap="false"
  >
    <div class="wallet-telegram-app-model__container">
      <div class="wallet-telegram-app-model__title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.balanceLabel') }}
      </div>

      <atomic-icon class="wallet-telegram-app-model__icon" id="close-dark" @click="closeWallet" />

      <tab-wallet :modelValue="walletModalType" hideTabTabs @update:modelValue="selectTab" />

      <div class="wallet-telegram-app-model__body">
        <modal-content-wallet-telegram-app-withdraw
          v-if="walletModalType === PayType.WITHDRAW"
          @closeWallet="closeWallet"
        />

        <modal-content-wallet-telegram-app-deposit v-else @closeWallet="closeWallet" />

        <div class="wallet-telegram-app-model__problems">
          <button class="wallet-telegram-app-model__problems-btn" @click="toSupport">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.anyProblems') }}
          </button>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import { ModalName } from '@skeleton/consts/modals';
  import { PayType } from '@skeleton/consts/method';

  const { getContent, localizePath } = useProjectMethods();
  const modalStore = useModalStore();
  const { closeModalSync } = modalStore;
  const { modalsSync } = storeToRefs(modalStore);
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());
  const transactionStore = useTransactionStore();
  const { walletModalType } = storeToRefs(transactionStore);

  const router = useRouter();

  const closeWallet = () => {
    selectTab(PayType.DEPOSIT);
    closeModalSync(ModalName.WALLET_TELEGRAM_APP);
  };

  const selectTab = (tab: string) => {
    walletModalType.value = tab;
  };

  const toSupport = (): void => {
    closeWallet();
    router.push(localizePath('/support'));
  };
</script>

<style src="~/assets/styles/components/modal/wallet-telegram-app.scss" lang="scss" />
